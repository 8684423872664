// Libraries
import React from 'react';
import { graphql } from 'gatsby';
import get from 'lodash/get';

// Assets
import citadelImage from '../../public/citadel.webp';

// Components
import Layout from '../components/layout';
import Hero from '../components/hero';
import ArticlePreview from '../components/article-preview';

class RootIndex extends React.Component {
  render() {
    const posts = get(this, 'props.data.allContentfulBlogPost.nodes');
    return (
      <Layout location={this.props.location}>
        <Hero image={citadelImage} useImageTag />
        <ArticlePreview posts={posts} />
      </Layout>
    );
  }
}

export default RootIndex;

export const pageQuery = graphql`
  query HomeQuery {
    allContentfulBlogPost(sort: { fields: [publishedDate], order: DESC }) {
      nodes {
        title
        description
        slug
        publishedDate(formatString: "MMMM Do, YYYY")
        tags
        heroImage {
          gatsbyImageData(
            layout: FULL_WIDTH
            placeholder: BLURRED
            width: 424
            height: 212
          )
        }
      }
    }
  }
`;
